<template>
  <div class="price" :style="{color: color}">
    <span class="sign">￥</span>
    <span class="num" :style="{fontSize: numSize+'px'}">{{getPrice}}</span>
  </div>
</template>

<script>
export default {
  name: "price",
  props: {
    price: {
      default: '0.00'
    },
    color: {
      default: "#FE0036"
    },
    numSize: {
      default: 20
    }
  },
  computed: {
    getPrice() {
      let price = Number(this.price);
      return price.toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  .sign {
    font-size: 12px;
  }
  .num {
    font-weight: 500;
  }
}
</style>