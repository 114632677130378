<template>
  <div class="result">
    <div class="breadcrumb flex-start">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{bread}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- <div class="head">
      <div class="row flex-between">
        <div class="left">分类：</div>
        <div class="right"></div>
      </div>
      <div class="row flex-between">
        <div class="left">作者：</div>
        <div class="right"></div>
      </div>
      <div class="row flex-between">
        <div class="left">语言：</div>
        <div class="right"></div>
      </div>
      <div class="row flex-between">
        <div class="left">纸质：</div>
        <div class="right"></div>
      </div>
    </div> -->
    <div class="goods">
      <div class="sort flex-start">
        <div class="item center" :class="[sortIndex == 0 ? 'sort-select' : '']" @click="sortChange(0)">
          <span>综合</span>
          <img src="@/assets/down_white.png" v-if="sortIndex == 0">
          <img src="@/assets/down_gray.png" v-else>
        </div>
        <div class="item center" :class="[sortIndex == 1 ? 'sort-select' : '']" @click="sortChange(1)">
          <span>销量</span>
          <img src="@/assets/down_white.png" v-if="sortIndex == 1">
          <img src="@/assets/down_gray.png" v-else>
        </div>
        <div class="item center" :class="[sortIndex == 2 ? 'sort-select' : '']" @click="sortChange(2)">
          <span>新品</span>
          <img src="@/assets/down_white.png" v-if="sortIndex == 2">
          <img src="@/assets/down_gray.png" v-else>
        </div>
        <div class="item center" :class="[sortIndex == 3 ? 'sort-select' : '']" @click="sortChange(3)">
          <span>价格</span>
          <img src="@/assets/down_white.png" v-if="sortIndex == 3">
          <img src="@/assets/down_gray.png" v-else>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="cover center" @click="toDetail(item.ItemId)">
            <img :src="item.MainPic" alt="">
          </div>
          <Price :price="item.SkuList[0].SalePrice" />
          <div class="name over-hidden2" @click="toDetail(item.ItemId)">
            <span>[{{item.SkuList[0].GoodsSource}}]</span>
            {{item.Title}}
          </div>
          <div class="btn flex-between">
            <div class="left center">
              <img src="@/assets/star2.png" alt="">
              <span>收藏</span>
            </div>
            <div class="right center" @click="addCart(item)">
              <img src="@/assets/cart2.png" alt="">
              <span>购物车</span>
            </div>
          </div>
        </div>
        <div class="noData center" v-if="list.length == 0">暂无此类书籍</div>
      </div>
    </div>
    <div class="page" v-if="total > 25">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="show"
      width="30%"
      :before-close="close"
      @close="close"
    >
      <span class="tips"><i class="el-icon-success" style="fontSize: 30px;color: #67C23A;marginRight: 5px"></i> 添加成功</span>
      <div class="success">
        <el-button @click="show = false">继续看看</el-button>
        <el-button type="primary" @click="toShoppingCart">去购物车结算</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getItemList} from "@/api/common"
import Price from "@/components/price"
export default {
  components: {
    Price
  },
  data() {
    return {
      bread: "",
      list: [],
      total: 0,
      sortIndex: 0,
      searchText: "",
      ParentPlatCatId: "",
      PlatCatCode: "",
      LanguageList: [],
      show: false
    }
  },
  created() {
    
  },
  mounted() {
    this.getQuery();
  },
  computed: {
    queryParams() {
      return this.$route.query
    }
  },
  watch: {
    queryParams() {
      this.getQuery();
    }
  },
  methods: {
    getQuery() {
      this.bread = this.$route.query.name;
      if(this.$route.query.ParentPlatCatId) {
        this.ParentPlatCatId = this.$route.query.ParentPlatCatId
      }
      if(this.$route.query.searchText) {
        this.searchText = this.$route.query.searchText
      }
      if(this.$route.query.PlatCatCode) {
        this.PlatCatCode = this.$route.query.PlatCatCode
      }
      if(this.$route.query.LanguageList) {
        let str = decodeURIComponent(this.$route.query.LanguageList)
        this.LanguageList = str.split(',')
      }
      this.getList()
    },
    async getList() {
      let params = {
        SearchText: this.searchText,
        PlatCatCode: this.PlatCatCode,
        LanguageList: this.LanguageList,
        _PageIndex: 1,
        _PageSize: 25,
      }
      let res = await getItemList(params);
      if(res.IsSuccess) {
        this.list = res.Data;
        this.total = res.DataCount
      }
    },
    toDetail(id) {
      this.$router.push({
        name: "Detail",
        query: {
          id: id
        }
      })
    },
    sortChange(i) {
      this.sortIndex = i
    },
    addCart(obj) {
      let arr = localStorage.cart ? JSON.parse(localStorage.cart) : [];
      arr.push({
        Id: obj.ItemId,
        goodsName: obj.Title,
        goodsPic: obj.MainPic,
        count: 1,
        price: obj.SkuList[0].SalePrice,
        stock: obj.SkuList[0].Stock
      })
      localStorage.setItem('cart', JSON.stringify(arr));
      this.show = true;
    },
    close() {
      this.show = false;
    },
    toShoppingCart() {
      this.$router.push({
        name: "ShoppingCart"
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.result {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 119px;
  .breadcrumb {
    width: 100%;
    height: 50px;
    align-items: center;
  }
  .head {
    width: 100%;
    padding: 40px;
    background: #FFFFFF;
    .row {
      width: 100%;
      padding: 18px 0;
      font-size: 14px;
      line-height: 20px;
      color: #202020;
      border-bottom: 1px dashed #EAEAEA;
      .left {
        width: 70px;
        height: 20px;
        color: #939393;
      }
      .right {
        width: calc(100% - 90px);
      }
    }
  }
  .goods {
    width: 100%;
    background: #FFFFFF;
    // margin-top: 30px;
    .sort {
      width: 100%;
      height: 48px;
      border-bottom: 1px solid #eaeaea;
      font-size: 14px;
      color: 202020;
      .item {
        height: 48px;
        padding: 0 20px;
        border-right: 1px solid #eaeaea;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 196px;
        margin: 0 20px 50px;
        .cover {
          width: 196px;
          height: 196px;
          img {
            max-width: 160px;
            max-height: 160px;
          }
        }
        .name {
          width: 100%;
          height: 40px;
          font-size: 14px;
          line-height: 20px;
          color: #1A3664;
          margin-top: 6px;
        }
        .btn {
          width: 153px;
          height: 28px;
          border: 1px solid #EAEAEA;
          margin-top: 6px;
          font-size: 14px;
          img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
          .left {
            width: 57px;
            height: 100%;
            border-right: 1px solid #EAEAEA;
            color: #cccccc;
          }
          .right {
            width: calc(100% - 57px);
            height: 100%;
            color: #0AC8F9;
            cursor: pointer;
          }
        }
      }
      .noData {
        width: 100%;
        height: 300px;
        font-size: 24px;
        color: #939393;
      }
    }
  }
  .page {
    width: 100%;
    margin-top: 67px;
    display: flex;
    justify-content: flex-end;
  }
}
.sort-select {
  background: #0AC8F9;
  color: #FFFFFF;
}
</style>